import React from 'react'

const MobileBanking: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  return (
    <svg
      width="42"
      height="56"
      viewBox="0 0 42 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2857_79025)">
        <rect
          x="1"
          y="8.75"
          width="40"
          height="38.5"
          fill="white"
          stroke="#00CC2C"
          strokeWidth="2"
        />
        <path
          d="M1 47.25H41V50.25C41 52.4591 39.2091 54.25 37 54.25H5C2.79086 54.25 1 52.4591 1 50.25V47.25Z"
          fill="#8CF9A4"
          stroke="#00CC2C"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M1 5.75C1 3.54086 2.79086 1.75 5 1.75H37C39.2091 1.75 41 3.54086 41 5.75V8.75H1V5.75Z"
          fill="#8CF9A4"
          stroke="#00CC2C"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M21 14.875V39.375"
          stroke="#00CC2C"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.75 18.7582C24.508 17.1981 14.3 16.0352 14.3 21.4059C14.3 27.8354 27.267 25.5666 27.267 31.2392C27.267 36.9118 18.835 36.4874 13 34.6421"
          stroke="#00CC2C"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2857_79025">
          <rect width="42" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MobileBanking
